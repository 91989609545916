/* 
 * @Author: 曹俊杰 
 * @Date: 2023-02-17 02:46:24
 * @Module:课程详情 
 */
 <template>
  <div></div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {

  },
  watch: {
    "$route.params": {
      async handler (params) {
        const { status, data, info } = await this.$api.getCourseDetail(params)
				console.log(params)
        if (status === 200) {
          this.$store.dispatch("course/setCourseDetail", data);
					console.log(data.passedLesson,data.lessons.length)
					console.log(data.lessons)
					console.log(data.lessons)
          if (data.passedLesson >= data.lessons.length)
            this.$router.push({ path: `/course/${data.uuid}/${data.lessons[data.lessons.length - 1].uuid}` })
          else
            this.$router.push({ path: `/course/${data.uuid}/${data.lessons[data.passedLesson].uuid}` })
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
        // this.$router.push({ path: `/course/${params.uuid}/33221` })
        // const data = courseDetail


      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
</style>