import { render, staticRenderFns } from "./CourseDeatil.vue?vue&type=template&id=e759ce5e&scoped=true&"
import script from "./CourseDeatil.vue?vue&type=script&lang=js&"
export * from "./CourseDeatil.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e759ce5e",
  null
  
)

export default component.exports